<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent>
        <v-card class="addCard">
          <v-card-title>
            <!-- <span class="text-h5"
              >Edit Record in
              {{
                num == 0
                  ? "WhatsApp"
                  : num == 1
                  ? "Call"
                  : num == 2
                  ? "Link"
                  : "Location"
              }}</span
            > -->
            <span class="text-h5">Edit</span><br /><br />
          </v-card-title>
          <!-- <select
            v-if="myArr.length !== 0"
            v-model="selectedData"
            class="mySelect"
          >
            <option
              @click="handleClick"
              :value="item"
            >
              {{ myArr[num][0] }}
            </option>
          </select> -->
          <!-- <select class="selection" v-if="myArr.length !== 0">
            <option disabled selected>{{ myArr[num][0] }}</option>
          </select>
          <v-card-text>
            <v-container>
              <v-col cols="12">
                <v-text-field
                  class="tfw"
                  label="Enter Key"
                  v-model="keys"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="tfw"
                  label="Enter Value"
                  v-model="vals"
                  required
                ></v-text-field>
              </v-col>
            </v-container>
          </v-card-text> -->
          <select class="selection" v-if="myArr.length !== 0">
            <option disabled selected>{{ myArr[num][0] }}</option>
          </select>
          <div v-if="myArr.length !== 0" class="alignmentClass">
          <div v-if="myArr[num][0] !== 'Download' && myArr[num][0] !== 'Vcard' && myArr[num][0] !== 'Save Contact'">
            <v-col cols="12">
              <v-text-field
                class="tfw"
                label="Enter Label"
                v-model="keys"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :hint="myArr[num][0]=='Whatsapp'?'Add WhatsApp Number with Country-code':''"
                class="tfw"
                label="Enter Value"
                v-model="vals"
                required
              ></v-text-field>
            </v-col>
          </div>
          <div v-else-if="myArr[num][0] === 'Download'">
            <v-col cols="12">
              <v-text-field
                class="tfw"
                label="Enter Label"
                v-model="keys"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              Upload File :-
              <input type="file" @change="process($event)" />
            </v-col>
          </div>
          <div v-else-if="myArr[num][0] === 'Vcard' || myArr[num][0] === 'Save Contact'">
            <v-col cols="12">
              <v-text-field
                class="tfw"
                label="Enter Label"
                v-model="keys"
                required
              ></v-text-field>
            </v-col>
          </div>
          </div>
          <v-card-actions>
            <!-- <v-btn
              color="blue-darken-1"
              text
              @click="
                addRecord();
              "
            >
              Add
            </v-btn> -->
            <!-- <v-btn
              color="blue-darken-1"
              text
              @click="
                updateRecord();
              "
            >
              Update
            </v-btn> -->
            <v-spacer></v-spacer>
            <v-btn
              color="red-darken-1"
              text
              @click="
                deleteRecord();
                saveRecord();
                closeDialog();
              "
            >
              Delete
            </v-btn>
            <v-btn color="blue-darken-1" text @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn
              color="blue-darken-1"
              text
              @click="
                updateRecord();
                saveRecord();
                closeDialog();
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addRec",
  emits: ["res", "closeDialog"],
  created() {
    this.$emit("res", this.toggle);
    console.log("created");
  },
  props: {
    num: Number,
    arr: Array,
    email: String,
    password: String,
  },
  computed: {
    computedDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
  components: {},
  methods: {
    process(event) {
      this.myFile = event.target.files[0];
      //alert(this.myFile.name);
      var myHeaders = new Headers();
        myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");

        var formdata = new FormData();
        formdata.append("hwId", this.$route.params.id);
        formdata.append("attachment", this.myFile, this.myFile.name);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
      fetch("https://api.bytecard.in/api/nfc/upload", requestOptions)
          .then((response) => response.text())
          .then((result) => this.writeFile(result))
          .catch((error) => console.log("error", error)); 
    },
    writeFile(result)
    {
      this.myFileUrl = JSON.parse(result).data;
      //alert(this.myFileUrl);
      alert("File Uploaded...");
    },
    saveRecord() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", "935c37eb-8c56-4041-8d56-7bcbe896aa0c");
      // var options = '{"';
      // for (var i = 0; i < this.myArr.length; i++) {
      //   if (i == 3) {
      //     options = options.concat(
      //       this.keyArr[i] + '":' + JSON.stringify(Object.values(this.myArr[i]))
      //     );
      //   } else {
      //     options = options.concat(
      //       this.keyArr[i] +
      //         '":' +
      //         JSON.stringify(Object.values(this.myArr[i])) +
      //         ',"'
      //     );
      //   }
      // }
      // options = options.concat("}");
      var options;
      var i, j;
      if (this.myArr.length == 0) {
        options = '{"items":[]}';
      } else {
        options = '{"items":[';
        for (i = 0; i < this.myArr.length; i++) {
          options = options.concat('{"');
          for (j = 0; j < 3; j++) {
            if (j === 2) {
              options = options.concat(
                this.keyArr[j] + '":' + JSON.stringify(this.myArr[i][j])
              );
            } else {
              options =
                options.concat(
                  this.keyArr[j] + '":' + JSON.stringify(this.myArr[i][j])
                ) + ',"';
            }
          }
          if (i !== this.myArr.length - 1) {
            options = options.concat("},");
          } else {
            options = options.concat("}]}");
          }
        }
      }
      var myUrl = '"' + this.$route.params.id + '"';
      console.log(myUrl);

      console.log("Options ->" + options);
      var raw =
        '{\r\n"hwId": ' +
        myUrl +
        ',\r\n"action":{ "data":[{"type":"' +
        this.selectedArr[0][0] +
        '", "label":"' +
        this.selectedArr[0][1] +
        '", "value":"' +
        this.selectedArr[0][2] +
        '"}], "num":"' +
        this.selectedNum +
        '", "url":"' +
        this.theUrl +
        '", "view":"' +
        this.myView +
        '"},\r\n"options":' +
        options +
        "}";
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.bytecard.in/api/nfc/update", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      console.warn(raw);
      //alert("Saved Successfully...");
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    updateRecord() {
      // if (this.selectedData === "") {
      //   alert("No record is selected...");
      // } else {
      //   for (var i = 0; i < this.myArr[this.num].length; i++) {
      //     if (this.myArr[this.num][i] == this.selectedData) {
      //       this.myArr[this.num][i] = { [this.keys]: this.vals };
      //     }
      //   }
      if(this.myArr[this.num][0] === "Download")
      {
        this.myArr[this.num] = [this.myArr[this.num][0], this.keys, this.myFileUrl];
      }
      else
      {
        this.myArr[this.num] = [this.myArr[this.num][0], this.keys, this.vals];
      }
      alert("Record Saved...");
    },
    handleClick() {
      let key = Object.keys(this.selectedData)[0];
      this.keys = key;
      this.vals = this.selectedData[key];
    },
    add() {
      for (var i = 0; i < this.arr.length; i++) {
        console.log(this.arr[i]);
      }
    },
    addRecord() {
      if (this.keys === "" || this.vals === "") {
        alert("Values of the Text Field cannot be empty...");
      }
      this.dialog1 = false;
      this.myArr[this.num].push({ [this.keys]: this.vals });
      alert("Record Saved...");
    },
    deleteRecord() {
      // for (var i = 0; i < this.myArr.length; i++) {
      //   if (this.myArr[i] === [this.selectedData, this.keys, this.vals]) {
      //     this.myArr[this.num].splice(i, 1);
      //   }
      // }
      // console.log(this.myArr);
      if (confirm("Are you sure you want to delete this record ?")) {
        if (this.num === this.myArr.length - 1) {
          this.myArr.pop();
        } else {
          this.myArr.splice(this.num, 1);
        }
        console.log(this.myArr);
        alert("Record Deleted...");
      }
    },
    openForm() {
      if (this.toggle == false) {
        this.toggle = true;
      } else {
        this.toggle = false;
      }
    },
    getData() {
      //if (this.email !== "" && this.password !== "") {
      this.myArr = this.arr;
      this.keys = this.myArr[this.num][1];
      this.vals = this.myArr[this.num][2];
      //  }
      var formdata = new FormData();
      console.log(this.arr1);
      //   console.log(this.$route.params.id);
      //   formdata.append("email", this.email);
      //   formdata.append("password", this.password);
      //   formdata.append("productId", "a9ec6071-ab8c-479e-b3d0-ee0cbfc68ae8");
      formdata.append("hwId", this.$route.params.id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.bytecard.in/api/nfc/get", requestOptions)
        .then((response) => response.text())
        .then((result) => this.printData(result))
        .catch((error) => alert(error));
    },
    printData(result) {
      var arr = JSON.parse(result);
      if (arr.status == false) {
        alert(arr.message);
        console.log(arr);
      } else {
        console.log(arr.data.options);
        //this.arr1 = Object.entries(arr.data.options);
        //this.tempArr = Object.values(arr.data.options.items);

        //this.arr1 = [["Link", "Google", "https://www.google.com"], ["Call", "Home", "9106884674"], ["Call", "Office", "9879364472"], ["Whatsapp", "Office", "9106884674"], ["Whatsapp", "Home", "7878962975"], ["Location", "Location", "https://maps.app.goo.gl/vKBKrocP7XaeJFH19"], ["Whatsapp", "Shop", "9106884674"]],
        this.tempSelectedArr = Object.values(arr.data.action.data);
        //alert(JSON.stringify(this.tempSelectedArr));
        for (var j = 0; j < this.tempSelectedArr.length; j++) {
          this.selectedArr.push(Object.values(this.tempSelectedArr[j]));
        }
        //console.log(this.selectedArr)
        //alert(this.selectedArr);
        this.selectedNum = parseInt(arr.data.action.num);
        this.theUrl = arr.data.action.url;
        this.myView = arr.data.action.view;
      }
    },
  },
  mounted() {
    this.getData();
  },
  data: () => ({
    dialog: true,
    dialog1: false,
    keys: "",
    vals: [],
    myArr: [],
    keyArr: ["type", "label", "value"],
    selectedData: "",
    selectedArr: [],
    tempSelectedArr: [],
    theUrl: "",
    myFile: "",
    myFileUrl: "",
    myView: ""
  }),
};
</script>
<style scoped>
.alignmentClass{
  margin-left: 10px;
}
.addCard{
  width: 50vh;
}
.tfw {
  width: 100%;
}
@media only screen and (max-width: 630px) {
  .tfw {
    width: 300px;
  }
}
@media only screen and (max-width: 400px) {
  .tfw {
    width: 100%;
  }
}
.mySelect {
  width: 100%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
  border: 2px solid black;
}
@media only screen and (max-width: 430px) {
  .mySelect {
    width: 60%;
    height: 60%;
  }
}
@media only screen and (max-width: 380px) {
  .mySelect {
    width: 50%;
    height: 50%;
  }
}
.pop {
  width: 100%;
}
@media only screen and (max-width: 460px) {
  .pop {
    width: 85%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .mySelect {
    width: 100%;
  }
}
.selection {
  background-color: white;
  text-align: center;
  color: rgb(96, 88, 88);
  width: 80%;
  height: 40px;
  border: 1px solid rgb(59, 57, 57);
  cursor: pointer;
  font-size: 15px;
  margin-left: 10%;
  margin-bottom: 7%;
}
</style>