export default {
    base_url: process.env.VUE_APP_BASE_API_URL,
    api_url: process.env.VUE_APP_API_URL,
    nfc_api_url: 'https://api.bytecard.in/api/nfc/',
    ApiCode:{
        InvalidCode: 202,
        NotLinkCode: 205,
        IncompleteProfile: 207
    },
    ByteNfcProductId: 'c9e92390-331c-4b0e-8b74-8f2a3516ff30',
    EmailPattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}