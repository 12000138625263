<template>
  <div>

    <div class="pa-4">
      <div class="text-h5">Complete Your Profile</div>
      <p style="font-size: 14px;color: #545b62;">We required your basic business details to complete your Registration with us. Please
        fill in the details below and continue to build your NFC ByteCard</p>
    </div>

    <v-form ref="form" v-model="isFormValid" class="pa-4">
      <v-card border><v-card-title class="mb-2">Company Details</v-card-title>
        <v-card-text>
          <v-row class="company-details">
                  <v-col cols="12" class="cursor-pointer text-center mb-4 mt-2" @click="uploadFileDialog=true">
                    <div v-if="form.logo">
                      <img :src="imgWpx(form.logo, 400)" class="aj-avatar-upload" @error="form.logo = ''" alt="company-logo">
                      <v-btn @click.stop="removeImage()" class="text-red-darken-4" style="position: relative; left: -16px; top: -20px" title="Remove Image"
                             icon="mdi-close" size="x-small" variant="tonal">
                      </v-btn>
                    </div>
                    <div v-else class="aj-avatar-upload bg-grey-lighten-3 row justify-center align-items-center mx-auto">
                      <v-icon class="text-primary">mdi-cloud-upload</v-icon>
                    </div>
                   <!-- <div class="ml-6" style="margin-top: 22px">
                      <span class="text-caption text-grey-lighten-2">Image size should be 512px x 512px, File type: PNG or JPG (File size: Max 1MB)</span>
                    </div> -->
                  </v-col>


            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.name" :rules="[v=>!!v||'Company Name is required']"
                            label="Company Name*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="subDomain" label="Sub-domain*" maxlength="20"
                            :rules="[v => !!v || 'Sub-domain is required',
                                     v => v.length >= 2 || 'Enter minimum 2 characters',
                                     v => /^[a-zA-Z0-9\-]+$/g.test(v) || 'Invalid Sub-domain Name']"/>
            </v-col>
           <!-- <v-col cols="12" md="6">
              <v-autocomplete variant="outlined" v-model="form.category" :items="[]" label="Category"
                        item-title="name" item-value="id"/>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
      <v-card border class="mt-4"><v-card-title class="mb-2">Personal Details</v-card-title>
        <v-card-text>
          <v-row class="person-details">
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.personName" :rules="[v=>!!v||'Person Name is required']"
                            label="Person Name*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.designation" label="Designation"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" label="Email*" disabled
                            v-model="form.email" :rules="[v=>!!v||'Email is required', v=>Constants.EmailPattern.test(v)||'Invalid Email']"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.mobile" :rules="[v=>!!v||'Contact Number is required']"
                            label="Contact Number*" maxlength="10"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.whatsapp" :rules="[v=>!!v||'WhatsApp Number is required']"
                            label="WhatsApp Number*" maxlength="10"/></v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card border class="mt-4 mb-4"><v-card-title class="mb-2">Address</v-card-title>
        <v-card-text>
          <v-row class="address">
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.addressLine1" :rules="[v=>!!v||'Address Line 1 is required']"
                            label="Address Line 1*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.addressLine2" label="Address Line 2"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.area" label="Area"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.city" :rules="[v=>!!v||'City is required']"
                            label="City/Dist*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.pincode" :rules="[v=>!!v||'Pincode is required']"
                            type="number" label="Pincode*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.state" :rules="[v=>!!v||'State is required']"
                            label="State*"/></v-col>
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" v-model="form.country" :rules="[v=>!!v||'Country is required']"
                            label="Country*"/></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="form-actions">
      <v-btn class="bg-primary mr-4" @click="handleSubmit" :loading="formLoading">Save</v-btn>
    </div>

    <UploadFile v-model:dialog="uploadFileDialog" @fileResponse="form.logo=$event" fileTypes=".jpg, .jpeg, .png"/>
    <Snackbar :toaster="toaster" @close="toaster.enable=false"/>
  </div>
</template>

<script>
import Constants from "@/constants/Constants";
// import {GET_BUSINESS_CATEGORIES} from "@/graphql/queries/organizations";
import axios from "axios";
import Snackbar from "@/common/Snackbar";
import UploadFile from "@/components/Upload/UploadFile";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Profile',
  components: {UploadFile, Snackbar},
 /* apollo:{
    businessCategories: {
      query: GET_BUSINESS_CATEGORIES,
      fetchPolicy: 'network-only'
    },
  }, */
  data(){
    return{
      Constants,
      form: {name:'', addressLine1:'', addressLine2:'', area:'', city:'', pincode:'', state:'',
             country:'', category:'', personName:'', designation:'', email:'', mobileCc:'+91', mobile:'',
             whatsappCc:'+91', whatsapp:'', logo:''},
      subDomain: '',
      businessCategories: [],
      isFormValid: false,
      formLoading: false,
      uploadFileDialog: false,
      toaster: {enable: false}
    }
  },
  methods:{
    async handleSubmit() {
      if(this.$refs.form.validate() && this.isFormValid){
        let domain = `${this.subDomain}.${location.host}`;
        // let domain = `${this.subDomain}.bytenfc.com`;
        let subDomainResponse = await this.checkSubDomainExist(domain);
        if(subDomainResponse.status) this.saveProfile(domain);
        else this.toaster = {enable:true, color:'red', message: 'Sub-domain already exist!'};
      }
    },
    async checkSubDomainExist(whiteLabeledDomain) {
      let request = {whiteLabeledDomain};
      let headers = {token: localStorage.token};
      try {
       let response = await axios.post(Constants.api_url+'checkWhiteLabeledDomainExists', request, {headers});
       return response.data;
      } catch (e) {
        console.log('checkDomainExist API error', e)
      }
    },
    saveProfile(whiteLabeledDomain){
      this.formLoading = true;
      let user = JSON.parse(localStorage.user);
      let headers = {token: localStorage.token};
      let request = {...this.form, pincode:this.form.pincode.toString(), whiteLabeledDomain, category:'d93ddd94-df9d-43d2-8af1-ef3898318c74', oId:user.o_id};
      axios.post(Constants.api_url+'completeBusinessProfile', request, {headers}).then(response => {
        if(response.data.status){
          this.registerNFC(user);
        }else{
          this.toaster = {enable:true, color:'red', message: response.data.message};
          this.formLoading = false;
        }
      }).catch(e => console.log('registerProfile API error', e))
    },
    registerNFC(user){
      let hwId = this.$route.params.id;
      let request = {oId:user.o_id, cardId:hwId};
      axios.post(Constants.api_url+'registerNFC', request).then(response => {
        if(response.data.status){
          this.toaster = {enable:true, color:'green', message: `Profile saved Successfully`};
          localStorage.setItem('user', JSON.stringify({...user, is_completed:true, hwId}));
          this.$router.push(`/${hwId}/admin`);
        }else {
          this.toaster = {enable: true, color: 'red', message: response.data.message};
        }
        this.formLoading = false;
      }).catch(e => console.log('registerNFC API error', e))
    },
    removeImage() {
      this.form.logo = '';
    },
    imgWpx(path, size, type) {
      let sizes = path.split('/')[2]?.split('-') || [];
      if (!sizes.length || type === 'VIDEO') return Constants.base_url + path;
      if (!sizes.includes(String(size))) size = sizes.pop();
      return Constants.base_url + 'images/uploads/w-' + size + '/' + path.split('/').pop();
    },
  },
  created(){
    let user = localStorage.user ? JSON.parse(localStorage.user) : {};
    this.form.email = user.email;
  }
}
</script>

<style scoped>
.company-details>.v-col-12, .person-details>.v-col-12, .address>.v-col-12{
  padding-bottom: 0;
  padding-top: 0;
}
.form-actions{
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 13%) 0 -2px 10px 0;
  text-align: right;
}
.aj-avatar-upload {
  height: 74px;
  width: 74px;
  border-radius: 100%;
  object-fit: cover;
  border:1px solid lightgrey;
  /*background: lightgrey;*/
}
.cursor-pointer{
  cursor: pointer;
}
</style>